import React from 'react'
import Header from './headers/header'
import "./Hero.css"
import heroImg from "../assets/hero_image.png"
import heroImgBack from "../assets/hero_image_back.png"
import heart from "../assets/heart.png"
import calories from "../assets/calories.png"
import {motion} from "framer-motion"
import NumberCounter from "number-counter";

const Hero = () => {
    const transition = {type:"tween" , duration:3}
  return (
    <div>
      <div className="hero" id='home'>
        <div className="blur-h blur"></div>
        <div className="left-h">
            <Header/>
              {/* the best ad */}
            <div className="the-best-ad">
                <motion.div 
                 initial={{left:"240px"}}
                 whileInView={{left:"8px"}}
                 transition={transition}
                
                >
                </motion.div>
                <span>the best fitness club in the town</span>
            </div>
                {/* Hero Text */}
            <div className="hero-text">
                <div>
                    <span className='stroke-text'>Shape </span>
                    <span>Your</span>
                </div>
                <div>
                    <span>Ideal Body</span>
                  
                </div>
                <div>
                    <span>In here we will help you to shape and build your ideal body and live your life to fullest</span>
                </div>
            </div>
            {/* Figures */}
            <div className="figures">
                <div>
                    <span>
                        <NumberCounter end={140} start={50} delay={3} preFix="+"/>
                    </span>
                    <span>Expert coaches</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={970} start={850} delay={3} preFix="+"/>
                    </span>
                    <span>members joined</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={50} start={0} delay={3} preFix="+"/>
                    </span>
                    <span>fitness Programs</span>
                </div>
            </div>
            {/* buttons */}
            <div className="buttons">
                <button className="btn">Get Started</button>
                <button className="btn">Learn More</button>
            </div>
        </div>
        <div className="right-h">
            <button className="btn">Join Now</button>


         <motion.div 
         initial={{right:"-1rem"}}
         whileInView={{right:"4rem"}}
         transition={{...transition , type:"spring"}}
         
         
         
         className="heart-rate">
            <img src={heart} alt="" />
            <span>Heart Rate</span>
            <span>116 bpm</span>
         </motion.div>
         {/* hero images */}
         <img src={heroImg}  className="HeroImg" />
            <motion.img 
            initial={{right:"11rem"}}
            whileInView={{right:"20rem"}}
            transition={{...transition, type:"spring"}}
            
            
            src={heroImgBack}  className="HeroImgBack" />
               {/* hero images */}
               <div className="calories">
                <img src={calories} alt="" />
                <div>
                    <span>Calories Burned</span>
                    <span>220 kcal</span>
                </div>
               </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
