import './App.css';
import Hero from "./components/Hero"
import Program from './components/programs/program';
import Reason from './components/reasons/reson';
import Plan from './components/plans/plan'
import Test from './components/testimonials/test'
import Join from './components/join/join';
import Footer from './components/footer/footer';
function App() {
  return (
    <div className="App">
         <Hero/>
         <Program/>
         <Reason/>
         <Plan/>
         <Test/>
         <Join/>
         <Footer/>
         
    </div>
  );
}

export default App;
