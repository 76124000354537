import React from 'react'
import insta from "../../assets/instagram.png"
import linkDin from "../../assets/linkedin.png"
import git from "../../assets/github.png"
import logo from "../../assets/logo.png"
import './footer.css'

const Footer = () => {
  return (
    <div className='Footer-cont' id='footer'>
  
      <hr/>
    
      <div className="footer">
    
        <div className="s-links">
        <img src={insta} alt="" />
        <img src={linkDin} alt="" />
        <img src={git} alt="" />
        </div>
        <div className="logo-f">
        <img src={logo} alt="" />
      </div>
      
      </div>
     
     
    </div>
  )
}

export default Footer
