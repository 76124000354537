import React from 'react'
import "./reason.css"
import img1 from "../../assets/image1.png"
import img2 from "../../assets/image2.png"
import img3 from "../../assets/image3.png"
import img4 from "../../assets/image4.png"
import nb from "../../assets/nb.png"
import addidas from "../../assets/adidas.png"
import nike from "../../assets/nike.png"
import tick from "../../assets/tick.png"

const Reason = () => {
  return (
    <div>
        <div className="reasons" id='Reason'>
            <div className="left-r">
                <img src={img1} alt="" />
                <img src={img2}  alt="" />
                <img src={img3}  alt="" />
                <img src={img4}  alt="" />
            </div>
            <div className="right-r">
                <span>Some Reasons</span>
                <div>
                <span className='stroke-text'>Why </span>
                <span>Choose US?</span>
                </div>

                <div className="detalis-r">
                    <div>
                        <img src={tick} alt="" />
                        <span>OVER 140+ EXPERT COACHES</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span>TRAIN SAMATER AND FASTER</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span> 1 FREE PROGRAM FOR NEW MEMBER</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span>RELAIBLE PARTNERS</span>
                    </div>
                </div>
                <span style={{
                    color:"gray",
                    fontWeight:"bold"
                }}>Our Partners</span>
                <div className="partners">
                    <img src={nb} alt="" />
                    <img src={addidas} alt="" />
                    <img src={nike} alt="" />
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Reason
