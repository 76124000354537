import React from 'react'
import emailjs from "@emailjs/browser";
import { useRef } from 'react';
import "./join.css"

const Join = () => {
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_rbk6xi6', 'template_kluhw1k', form.current, 'zD8k3SXMfXoSCdDVh')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className='join' id='Join'>
      <div className="left-j">
        <hr/>
      <div>
        <span className='stroke-text'>READY TO </span>
        <span>LEVEL UP</span>
      </div>
      <div>
        <span>YOUR BODY </span>
        <span className='stroke-text'>WITH US?</span>
      </div>
      </div>
      <div className="right-j">
        <form ref={form} className='eamil-Cont' onSubmit={sendEmail}>
        <input type="text" placeholder='Your Email' name='user_email' />
        <button className='btn btn-j'>join Now</button>
        </form>

      </div>

    </div>
  )
}

export default Join
