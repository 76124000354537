import React from 'react'
import "./programe.css"

import { programsData } from "../../data/programsData";
import rightArrow from "../../assets/rightArrow.png";


const Program = () => {

  return (
    
    
    <div className='programe' id='programe'>
        <div className="prog-header">
            <span className='stroke-text'>Explore Our</span>
            <span>Programs </span>
            <span className='stroke-text'>to shape you</span>
        </div>
        <div className="prog-categories">
        {programsData.map((items)=>{
          return (
           <div className="categorys">
           {items.image}
           <span>{items.heading}</span>
           <span>{items.details}</span>
           <div className="join-now">
            <span>Join Now</span>
            <img src={rightArrow} alt="" />
            </div>
         </div>
         )
        })}
             
        
             
      
 
        </div>
    </div>
  )
}

export default Program;
