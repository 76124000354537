import React from 'react'
import './plan.css'
import {plansData} from "../../data/plansData"
import whiteTick from '../../assets/whiteTick.png'

const Plan = () => {
  
  return (
    <>
    <div className='prog-header'>
       
        <span className='stroke-text'>Ready To Start</span>
        <span>Your Journey</span>
        <span className='stroke-text'>With Us</span>
    </div>

            {/* plans */}
            <div className="plans" id='plans'>
                
            {plansData.map((plan, i)=>{
               return(
                <div className="plan" key={i}>
                {plan.icon}
                <span>{plan.name}</span>
                <span> ${plan.price}</span>

                <div className="features">
               {plan.features.map((feature , i)=>{
                
                return(
                 
                    <div className="feature" key={i}>
                        <img src={whiteTick} alt="" />
                        <span key={i}>{feature}</span>
                        
                    </div>

                     
                  
                       
                    
                )
            
                
               })}
            </div>
            <button className="btn">Join Now</button>
            </div>
            
               )
            })}
        </div>
   
     </> 
  )

}


export default Plan
