import React from 'react'
import './text.css'
import {testimonialsData} from "../../data/testimonialsData"
import { useState } from 'react'
import leftArrow from "../../assets/leftArrow.png"
import rightArrow from "../../assets/rightArrow.png"
import {motion} from "framer-motion"





const Test = () => {
    const transition = {type:"tween" , duration:1}

    const[selected , setSelected] = useState(0);
    const tLength = testimonialsData.length;


    // let left = ()=>{
    //     return selected === 0 ? setSelected(tLength - 1):setSelected((prev)=>{prev -1});
        
       
    // }
    // let right = ()=>{
    //     return selected === tLength-1 ? setSelected(0): setSelected((prev)=>{prev + 1});

    // }
  return (
 


    <div className='testimonilas' id='test'>
        <div className="left-t">
        <span>Testimonilas</span>
        <span className='stroke-text'>What They</span>
        <span>say about us</span>
        <motion.span
        key={selected}
        initial={{opacity:0, x:-100}}
        animate={{opacity:1, x:0}}
        exit={{opacity:0, x:100}}
        transition={transition}
        
        >{testimonialsData[selected].review}</motion.span>
        <span style={{color:"var(--orange)" , fontWeight:"bold"}}>{testimonialsData[selected].name} - {testimonialsData[selected].status}</span>
        
        </div>
        <div className="right-t">
            <motion.div
            initial={{opacity:0, x:-100}}
            whileInView={{opacity:1, x:0}}
            transition={transition}

            ></motion.div>
            <motion.div
               initial={{opacity:0 , x:100 }}
               whileInView={{opacity:1 ,x:0}}
               transition={transition}
            ></motion.div>
            <motion.img 
             key={selected}
             initial={{opacity:0, x:100}}
             animate={{opacity:1, x:0}}
             exit={{opacity:0, x:100}}
             transition={transition}
            src={testimonialsData[selected].image} alt="" />

            <div className="arrows">
                <img onClick={()=>{
                    selected===0 ? setSelected(tLength - 1):setSelected((prev)=> prev -1)
                }} src={leftArrow} alt="" />
                <img onClick={()=>{
                    selected===tLength -1  ? setSelected(0):setSelected((prev)=> prev +1)
                }} src={rightArrow} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Test
