import React, { useState } from 'react'
import logo from "../../assets/logo.png"
import bars2 from "../../assets/bars2.png"
import { Link } from 'react-scroll';

import "./header.css"

const Header = () => {

const mobile = window.innerWidth<=786 ? true:false;
const [openMenu, setOpenMneu] = useState(false)



  return (
    <div className='header'>
      <img src={logo} alt="" className='img' />
    {openMenu === false && mobile == true ? (
      <div onClick={(()=> setOpenMneu(true))}>
        <img src={bars2} alt="" style={{width:"2rem", height:"2rem" ,borderRadius:"3px"}} />
      </div>
    ): 
    <ul className='header-menu'>
    <li>
      <Link
      onClick={(()=> setOpenMneu(false))}
      to="home"
      spy={true}
      smooth={true}
      >
      Home
      </Link>
    </li>
    <li>
      <Link
       onClick={(()=> setOpenMneu(false))}
       to="programe"
       spy={true}
       smooth={true}
      >
      Program
      </Link>
    </li>
    <li >
      <Link
        onClick={(()=> setOpenMneu(false))}
        to="Reason"
        spy={true}
        smooth={true}
      >
      Why Us
      </Link>

    </li>
    <li >

      <Link
         onClick={(()=> setOpenMneu(false))}
         to="plans"
         spy={true}
         smooth={true}
      >
      Plans
      </Link>
    </li>
    <li>
      <Link
        onClick={(()=> setOpenMneu(false))}
        to="test"
        spy={true}
        smooth={true}
      >
      Testimonilas
      </Link>
      </li>
  </ul>
    }
     

    </div>
  )
}

export default Header
